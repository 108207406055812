<template>
    <div class="login-reminder-box" v-show="isShow">
        <div class="tips">
          <div class="cancel"></div>
          <div class="text">登陆后可探索更多内容</div>
        </div>
        <div class="login" @click="handleClick">
          <div class="login-buttton">登录</div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LoginReminder',
  components: {
  },
  activated() {
    
  },
  data(){
      return {
          isShow: false,
      }
  },
  methods:{
      show(){
          this.isShow = true
      },
      close(){
          this.isShow = false
      },
      handleClick(){
        sessionStorage.setItem('fromPath', this.$route.path)
        if(this.$route.query.tid){
          this.$router.push({ path:'/login', query:{ tid: this.$route.query.tid } })
        } else {
          this.$router.push('/login')
        }
      }
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.login-reminder-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: calc(55px + constant(safe-area-inset-bottom));
  bottom: calc(55px + env(safe-area-inset-bottom));
  height: 50px;
  line-height: 50px;
  max-width: 625px;
  background: rgba(56,58,71,0.8);
  z-index: 1000;
  color: #fff;
  text-align: left;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  .tips {
    display: flex;
    align-items: center;
    .cancel {
      margin-right: 10px;
      color: #fff;
    }
    .text {
    }
  }
  .login {
    display: flex;
    align-items: center;
    .login-buttton {
      color: #fff;
      background: #ec745b;
      height: 28px;
      padding: 0px 20px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      font-size: 15px;
    }
  }
}
</style>
