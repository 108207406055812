<template>
    <div class="goods-card-box">
        <div class="goods-card" >
            <div class="left-card" >
                <div v-for="(evenList) in listsEvenIndex" :key="evenList.gid" class="left-single-card" @click="goGoodsDesc(evenList.gid)">
                    <div v-lazy-container="{
                         selector: 'img', error: require('../../assets/images/default-shop-desc-swiper.png'),
                         loading:require('../../assets/images/default-shop-desc-swiper.png') }">
                        <img :data-src="evenList.imgUrl" alt="" height="100%" width="100%" style="min-height:180px;">
                    </div>
                    <div class="title">{{ evenList.title }}</div>
                    <div class="row">
                        <div class="price"><span v-show="evenList.price !== ''">￥</span>{{ evenList.price }}</div>
                        <!-- <div class="amount"><span>库存</span>{{ evenList.amount }}</div> -->
                    </div>
                </div>
            </div>
            <div class="right-card">
                <div  v-for="(oddList) in listsOddIndex" :key="oddList.gid" class="right-single-card" @click="goGoodsDesc(oddList.gid)">
                    <div v-lazy-container="{
                         selector: 'img', error: require('../../assets/images/default-shop-desc-swiper.png'),
                         loading:require('../../assets/images/default-shop-desc-swiper.png') }">
                        <img :data-src="oddList.imgUrl" alt="" height="100%" width="100%" style="min-height:180px;">
                    </div>
                    <div class="title">{{ oddList.title }}</div>
                    <div class="row">
                        <div class="price"><span v-show="oddList.price !== ''">￥</span>{{ oddList.price }}</div>
                        <!-- <div class="amount"><span>库存</span>{{ oddList.amount }}</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'GoodsCard',
  mounted(){
  },
  data(){
    return {
    }
  },
  methods:{
    goGoodsDesc(gid){
        this.$emit('goGoodsDesc', gid)
    }
  },
  props:{
    listsOddIndex:{
        type: Array,
        default: ()=>[]
    },
    listsEvenIndex:{
        type: Array,
        default: ()=>[]
    }
  }
}
</script>
<style scoped lang="scss">
.goods-card-box {
    // padding: 0px 0 15px;
    padding: 0px 0 5px;
    .goods-card {
        display: flex;
        width: 100%; 
        text-align: left;
        .left-card {
            width: 50%;
            .left-single-card {
                padding: 0 0 5px;
                border-radius: 10px;
                background: #fff;
                margin: 10px 2px 5px 5px;
                box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
                .title {
                    padding: 0 10px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    min-height: 42px;
                }
                .row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 10px 0;
                    min-height: 26px;
                    .price {
                        font-size: 18px;
                        span {
                            font-size: 14px;
                        }
                    }
                    .amount {
                        font-size: 14px;
                        color: #969696;
                        span {
                            margin-right: 3px;
                        }
                    }
                }
                img {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    background: #f3f3f3;
                }
            }
        }
        .right-card {
            flex: 1;
            .right-single-card {
                padding: 0 0 5px;
                background: #fff;
                border-radius: 10px;
                margin: 10px 5px 5px 2px;
                box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
                .title {
                    padding: 0 10px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    min-height: 42px;
                }
                .row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 10px 0;
                    min-height: 26px;
                    .price {
                        font-size: 18px;
                        span {
                            font-size: 14px;
                        }
                    }
                    .amount {
                        font-size: 14px;
                        color: #969696;
                        span {
                            margin-right: 3px;
                        }
                    }
                }
                img {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    background: #f3f3f3;
                }
            }
        }
    }
}
</style>
