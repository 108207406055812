<template>
    <div class="shop-box" id="shop">
        <div class="search" v-if="$store.state.userAgent === 'Mobile'">
          <div class="fake-input" @click="goSearch">搜索你要找的商品</div>
          <img src="../../assets/images/search.png" alt="" width="17" height="17" class="searchImg">
          <div class="orderImg">
            <img src="../../assets/images/order-grey.png" alt="" width="26" height="26" @click="goOrderPage">
          </div>
        </div>
      <!-- <img src="../../assets/images/back-white.png" alt="" v-if="$route.path === '/shop' && $store.state.userAgent === 'Mobile'" class="back-img" width="30" height="30" @click="goBack"> -->
        <div class="swiper" v-show="$store.state.userAgent === 'Mobile'">
          <van-swipe class="my-swipe" :autoplay="3000" :duration="800" indicator-color="white" lazy-render>
            <van-swipe-item v-for="(imgurl, i) in swipeImgUrl" :key="i">
              <!-- 电商banner比例16：5 即355：110-->
              <div v-lazy-container="{
                   selector: 'img', error: require('../../assets/images/default-shop-desc-swiper.png'),
                   loading:require('../../assets/images/default-shop-desc-swiper.png') }">
                <img :data-src="imgurl" alt="" width="100%" height="110" style="border-radius:10px;">
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="category" v-show="firstCategoryLabelList && firstCategoryLabelList.length > 0">
          <!-- 待优化 -->
          <div class="first-category-row" style="margin: 10px 0 15px;" v-if="firstCategoryLabelList.length < 0">
            <div class="first-category">
              <div class="first-category-item" v-for="(list) in firstCategoryLabelList" :key="list.id" @click="goCategoryPage(list.id)">
                <img src="../../assets/images/first-category.png" alt="" width="55px" height="55px">
                <div class="label">{{ list.name }}</div>
              </div>
            </div>
          </div>
          <!-- 当前使用 -->
          <div class="first-category-lg" v-else>
              <div v-for="(list, index) in firstCategoryLabelList" :key="list.id + index" @click="goCategoryPage(list.id)">
                <div class="first-category-item" v-if="index < 10 && list.mini_img_url" >
                  <img :src="list.mini_img_url" alt="" width="55px" height="55px">
                  <div class="label">{{ list.name }}</div>
                </div>
              </div>
          </div>
          <div class="first-category-row" v-if="firstCategoryLabelList.length > 10">
            <div class="first-category">
              <div v-for="(list, index) in firstCategoryLabelList" :key="list.id + index + index" @click="goCategoryPage(list.id)">
                <div class="first-category-item" v-if="index >= 10 && list.mini_img_url">
                  <img :src="list.mini_img_url" alt="" width="45px" height="45px">
                  <div class="label" style="font-size:12px; min-width: 50px;">{{ list.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <goods-card :listsEvenIndex="listsEvenIndex" :listsOddIndex="listsOddIndex" @goGoodsDesc="goGoodsDesc"/>
        <!-- <div :class="[$store.state.userAgent === 'Mobile' ? 'load-more': 'load-more-bottom']"  v-if="lists.length !== total || !lists.length">正在加载中...</div>
        <div :class="[$store.state.userAgent === 'Mobile' ? 'load-more': 'load-more-bottom']" v-else>已经到底部啦！</div> -->
        <div :class="[$store.state.userAgent === 'Mobile' ? 'load-more': 'load-more-bottom']" v-show="!loading">已经到底部啦！</div>
        <login-reminder ref="loginReminder"/>
    </div>
</template>

<script>
import GoodsCard from '../../components/card/GoodsCard.vue'
import { querySwiperByStatus, queryOnSaleGoodsByUid, queryAllFirstCategoryByShopId, wxSdk } from '@/api/api'
import wx from "weixin-js-sdk";
import LoginReminder from '../../components/dialog/LoginReminder.vue';
const seo = require('../../../config/index')
import { getopenIdByCode } from '@/utils/index';

export default {
  name: 'Shop',
  components: {
    GoodsCard,
    LoginReminder,
  },
  created(){

  },
  mounted(){
    if(this.$store.state.userAgent !== 'Mobile'){
        document.getElementById("shop").style.cssText= 'margin-top: 58px';
    }
    this.getData()
    getopenIdByCode()
  },
  activated(){
    this.wxShare()
    document.title = seo.config.seoPage.shopPage.title
    this.isLogin = localStorage.getItem('token') ? true : false
    history.pushState(null, null, document.URL); 
    window.addEventListener("popstate", this.myBack, false);
    if(!this.isLogin){
      this.$refs.loginReminder.show()
    } else {
      this.$refs.loginReminder.close()
    }
  },
  deactivated(){
    window.removeEventListener("popstate", this.myBack, false); 
  },
  data:()=>{
    return {
      swipeImgUrl:[],
      lists:[],
      listsOddIndex:[],
      listsEvenIndex:[],
      firstCategoryLabelList:[],
      isLogin: localStorage.getItem('token') ? true : false,
      loading: false,
    }
  },
  methods:{
    getData(){
      querySwiperByStatus({ status:'5' }).then((res)=>{
        this.swipeImgUrl = res.data.data.mini_main_img_url.split(',')
      })
      this.getCategory()
      this.loading = true
      queryOnSaleGoodsByUid({ uid: '1000001' }).then((res)=>{
        this.lists.length = 0
        this.listsEvenIndex.length = 0
        this.listsOddIndex.length = 0
        res.data.data.data.forEach((item)=>{
            let temp = {}
            temp.gid = item.gid
            temp.imgUrl = item.mini_main_list_img_url
            temp.title = item.title
            temp.price = item.price
            temp.amount = item.amount
            this.lists.push(temp)
        })
        for(let i = 0; i < this.lists.length; i++){
            if(i % 2 === 0){
                this.listsEvenIndex.push(this.lists[i])
            } else if(i % 2 === 1){
                this.listsOddIndex.push(this.lists[i])
            }
        }
        this.loading = false
      })
    },
    getCategory(){
      this.loading = true
      queryAllFirstCategoryByShopId({ shopId: 'yingjing' }).then((res)=>{
        let resData = res.data.data.data
        this.firstCategoryLabelList = []
        resData.forEach((item, index) => {
          let temp = {}
          temp.id = item.first_category_id
          temp.name = item.first_category_name
          temp.img_url = item.img_url
          temp.mini_img_url = item.mini_img_url
          if(index === 0){
            temp.isSelected = true
          } else {
            temp.isSelected = false
          }
          this.firstCategoryLabelList.push(temp)
        })
      })
    },
    goGoodsDesc(gid){
      if(Number(gid) > 0){
        this.$router.push({ path:'/shop-desc',  query:{ gid: gid }})
      }
    },
    goCategoryPage(id){
      sessionStorage.setItem('firstCategoryId', id)
      this.$router.push({ path:'/category' })
    },
    goBack(){
      this.$router.back()
    },
    myBack() {
      history.pushState(null, null, document.URL);
    },
    wxShare(){
        let url = seo.config.wxConfig.shopPage.link + this.$route.fullPath + '?share=wx'
        let title = seo.config.wxConfig.shopPage.title
        wxSdk({ url:url }).then(res=>{
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名
            jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
          });
          wx.ready(function () {
            wx.checkJsApi({
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
                success: function(res) {
                // “分享给朋友”及“分享到QQ”
                if (res.checkResult.updateAppMessageShareData || res.checkResult.onMenuShareAppMessage) {
                    wx.updateAppMessageShareData({ 
                      title: title, // 分享标题
                      desc: seo.config.wxConfig.shopPage.desc, // 分享描述
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl: seo.config.wxConfig.shopPage.imgUrl, // 分享图标
                      success: function () {
                          // 设置成功
                      }
                    })
                }
                // “分享到朋友圈”及“分享到 QQ 空间”
                if(res.checkResult.updateTimelineShareData || res.checkResult.onMenuShareTimeline){
                    wx.updateTimelineShareData({ 
                      title: title, // 分享标题
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl: seo.config.wxConfig.shopPage.imgUrl, // 分享图标
                      success: function () {
                          // 设置成功
                      }
                    })
                }
            }})
          })
        })
    },
    goSearch(){
      this.$router.push({ path:'/search', query:{ type:'goods' } })
      sessionStorage.setItem('fromPath','shop')
    },
    goOrderPage(){
      if(this.isLogin){
        sessionStorage.setItem('fromPath', '/shop')
        this.$router.push({ path:'/order' })
      } else {
        sessionStorage.setItem('fromPath', '/order')
        this.$router.push({ name:'Login', params:{ text:'查订单' } })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.search {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 625px;
  .fake-input {
    width: calc(100% - 26px);
    border: 1px solid #eee;
    box-sizing: border-box;
    margin: 10px 5px 10px 10px;
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    color: #78788e;
    background: #fff;
  }
  .searchImg {
    position: absolute;
    left: 25px;
    top: 21px;
  }
  .orderImg {
    margin: 4px 10px 0 0;
    min-width: 48px;
  }
}
.shop-box {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); 
  padding-bottom: env(safe-area-inset-bottom);
}
.swiper {
  padding: 70px 15px 10px;
  .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      text-align: center;
      background-color: #f3f3f3;
      min-height: 110px;
    }
}

.category {
  background: rgba(255,255,255,0.5);
  margin: 0 10px;
  padding: 10px 0;
  border-radius: 10px;
  min-height: 165px;
  .first-category-row {
    background: #f3f3f3;
    .first-category {
      margin: 0px 10px 0;
      display: flex;
      align-items: center;
      height: 80px;
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+, edge */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .first-category-item {
        margin: 0 5px 0;
        img {
          border-radius: 10px;
        }
        .label {
          font-size: 14px;
          display: flex;
          justify-content: center;
          min-width: 60px;
        }
      }
    }
  }
  
  .first-category-lg {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 0px 0;
    .first-category-item {
        margin: 2px 5px;
        img {
          border-radius: 10px;
        }
        .label {
          font-size: 14px;
          display: flex;
          justify-content: center;
          min-width: 60px;
        }
    }
  }
}

.back-img {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1101;
}

.load-more {
  text-align: center;
  color: #969699;
  padding: 0px 0 65px 0;
  font-size: 14px;
}
.load-more-bottom {
  text-align: center;
  color: #969699;
  padding: 0px 0 10px 0;
  font-size: 14px;
}

::v-deep .van-swipe {
    position: relative;
    overflow: hidden;
    cursor: grab;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 10px;
}
</style>